import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppLayout from "../Components/Common/AppLayout/AppLayout";
import Login from "../Pages/Login";

const App = () => {
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("trblg_token")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, []);

  return (
    <>
      {login ? (
        <AppLayout>
          <Outlet />
        </AppLayout>
      ) : (
        <Login />
      )}
    </>
  );
};

export default App;
