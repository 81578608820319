import axios from "axios";

type props = {
	url: string;
	method: string;
	data: any;
};

const url = "https://backend.theholidayfeed.com/api/blogs/";

const callApi = (props: props) => {
	return axios({
		url: url + props.url,
		method: props.method,
		data: props.data,
	});
};

export default callApi;
