import React, { Component } from "react";
import Content from "../../../Components/Common/Content/Content";
import ContentHead from "../../../Components/Common/Content/ContentHead";
import ContentBody from "../../../Components/Common/Content/ContentBody";
import Input from "../../../Components/Common/Custom/Input";
import TextEditor from "../../../Components/Common/TextEditor/TextEditor";
import InputField from "../../../Components/Common/Custom/InputField";
import withRouter from "../../../Utils/withRouter";
import callApi from "../../../Apis/callApi";
import UploadImage from "../../../Utils/UploadImage";
import { Country, City } from "country-state-city";
import axios from "axios";
import { toast } from "react-hot-toast";
import styles from "../../../Styles/blogs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import CreateNewBlog from "../CreateNewBlog";

class BlogDetails extends Component {
  render() {
    return <CreateNewBlog />;
  }
}

export default withRouter(BlogDetails);
