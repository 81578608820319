import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTableColumns, faBox, faGear } from "@fortawesome/free-solid-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";


type ISidebarData = { title: string; icon: IconProp; link: string };
type IBlogTableHead = { title: string };

export const sidebar_data: ISidebarData[] = [
	{ title: "Blogs", icon: faMailBulk, link: "/blogs" },
	{ title: "Ads", icon:"ad", link:"/ads"}, 
	{ title: "Custom", icon: faGear, link: "/custom"}
];

export const blog_table_head: IBlogTableHead[] = [
	{ title: "S.No" },
	{ title: "Blog Title" },
	{ title: "Actions" },
];
